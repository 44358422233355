@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars.module";

.container {
  width: 100%;
  flex-grow: 1;
  @include flex(column, space-between);
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 15px;
  background: $background;

  @include respond-to(m) {
    padding: 0 34px
  }

  @include respond-to(l) {
    padding: 0 50px
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-self: center;
  width: 100%;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
