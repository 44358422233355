@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

.subtitle {
  font-size: 16px;
  margin-bottom: 44px;
  color: $secondary_text;
  line-height: 24px;
  @include respond-to(m) {
    font-size: 16px;
    margin-bottom: 70px;
  }
  @include respond-to(l) {
    font-size: 18px;
    margin: 0 auto 44px auto;
    color: $primary_white;
  }
}

.choiceList {
  display: flex;
  align-items: flex-start;
}

.choiceListItem {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choiceImg {
  width: 56px;
  height: 56px;
  margin-bottom: 21px;
  @include respond-to(m) {
    width: 77px;
    height: 77px;
  }
}

.choice {
  cursor: pointer;
  &:hover {
    .choiceImg {
      filter: drop-shadow(0px 4px 20px rgba(255, 255, 255, 0.8))
    }
  }
}

.choiceDisabled {
  cursor: not-allowed;

  &:hover {
    .choiceImg {
      filter: none;
    }
  }
}

.choiceText {
  color: $secondary_text;
  @include respond-to(m) {
    font-size: 18px;
    color: $primary_text
  }
}
