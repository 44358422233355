@import "src/assets/scss/vars.module";

.wrapper {
  position: relative;
  border-radius: 20px;
  width: 340px;
  max-width: 95vw;
  max-height: 95vh;
  background: white;
  color: $text_black;
  overflow: hidden;
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
  font-family: Open Sans, sans-serif;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $primary_border;
  padding-top: 10px;
  padding-bottom: 10px;
}

.body {
  min-height: 475px;
  padding-top: 20px;
  height: 100%;
}

.bodyWrapper {
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
}

.bodyInProgress {
  visibility: hidden;
}

.controlWrapper {
  flex-shrink: 0;
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.control {
  fill: $text_black;
  width: 16px;
  height: 16px;
}

.priceWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divider {
  color: $text_black;
  margin: 15px auto;
  position: relative;
  text-align: center;
  overflow-x: hidden;
}

.dividerLabel {
  text-transform: uppercase;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 0;
    width: 1000px;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-top: thin solid #c4c4c4;
    transition: border-color 0.15s ease-in-out;
  }

  &::after {
    left: calc(100% + 8px)
  }

  &::before {
    right: calc(100% + 8px);
  }
}

.loader {

}

.loaderWrapper {
  pointer-events: none;
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}