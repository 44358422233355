@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";
@import "src/assets/scss/mixins";

.container {
  width: 300px;

  @include respond-to(m) {
    width: 340px
  }
}

.choiceList {
  margin-bottom: -20px;
}

.choiceListItem {
  padding-bottom: 20px;
}

.choice {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 5px 13px;
  border: 1px solid transparent;
  background: linear-gradient($background, $background) padding-box, $primary_btn_gradient border-box;
  border-radius: 30px;
  cursor: pointer;
  @include transition(all, 100ms, ease-in-out);

  &:hover {
    background: $primary_btn_gradient;
    @include scale(1.03);
  }

  &:active {
    @include scale(1.1);
  }

  @include respond-to(m) {
    min-height: 44px;
    padding: 6px 32px;
  }
}

.choiceActive {
  background-color: $primary_violet;
}

.choiceText {
  color: $primary_text;
  font-size: 16px;
  line-height: 24px;
}

.choiceEmojiWrapper {
  margin-left: auto;
  flex-shrink: 0;
}

