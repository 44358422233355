// breakpoints
$small: 580px;
$medium: 1024px;
$large: 1366px;

// new colors for mirror
$background: #277BAB;
$primary_white: #FBFBFF;
$secondary_text: #E0E0E0;
$primary_border_backward_gradient: linear-gradient(38.65deg, #B06AB3 27.36%, #4568DC 87.78%);
$primary_btn_gradient: linear-gradient(90.6deg, #975BDD, #FC9F33);

// usage old colors
$background_gradient: linear-gradient(180deg, #141333 0%, #202261 70.63%, #3A237A 100%);

// old colors
$primary_text: #F2F3FD;
$error: #FF6060;
$success: #219653;
$text_black: #2F2E37;
$typography_black: #3F404C;
$typography_grey: #828282;
$primary_violet: #8E8CF0;
$secondary_violet: #9974F6;
$primary_border: #BDBDBD;
$border_violet: #3C3C84;
$primary_blue: #27376C;
$background_light_gradient: linear-gradient(182.59deg, #3E1D76 -3.21%, #56CCF2 117.2%);
$primary_gradient: linear-gradient(90.6deg, #6A4DBC 0.47%, #F2994A 137.94%);
$secondary_gradient: linear-gradient(90.6deg, rgba(106, 77, 188, 0.5) 0.47%, rgba(242, 153, 74, 0.5) 137.94%);
$primary_border_gradient: linear-gradient(38.65deg, #4568DC 27.36%, #B06AB3 87.78%);
$primary_postQuiz_gradient: linear-gradient(180deg, #141333 0%, rgba(20, 19, 51, 0) 100%);
$background_secondary_gradient : linear-gradient(109.66deg, #FFFFFF 1.11%, rgba(255, 255, 255, 0.23) 115.23%);

// dimensions
$modal_padding: 20px;

:export {
  maximalMobileWidth: $small;
  maximalTabletWidth: $medium
}
