@import "src/assets/scss/vars.module";
@import "src/assets/scss/mixins";
@import "src/assets/scss/breakpoints";

.block {
  margin-bottom: 30px;
  @include respond-to(m) {
    margin-bottom: 130px
  }
  @include respond-to(l) {
    margin-top: 70px;
    margin-bottom: 0
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  width: 300px;
  padding: 5px 10px;
  align-self: center;
  border-radius: 8px;
  outline: none;
  border: none;
  color: $primary_text;
  font-size: 16px;
  line-height: 24px;
  height: 40px;

  @include respond-to(m) {
    width: 340px;
    height: 44px;
  }

  @include respond-to(l) {
    width: 380px
  }
}

.buttonIcon {
  display: inherit;
  margin-left: -6px;
  margin-right: 12px;
}

.loadingIndicator {
  color: #FFFFFF !important;
}

.buttonLabel {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.button {
  background: $primary_btn_gradient;
  @include transition(all, 150ms, ease-in-out);

  &:active {
    @include scale(1.03)
  }
}

.buttonSuccess {
  background: $success;
  border-radius: 8px;
}

.disabled {
  cursor: not-allowed;
  background: $secondary_gradient
}

