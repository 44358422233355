@import "src/assets/scss/breakpoints";
@import "src/assets/scss/vars.module";

$sectionWidth: 1366;
$sectionWidthTablet: 768;
$sectionWidthMobile: 360;


.sectionButtonWrapper {
  position: absolute;
  z-index: 1;
  width: 290px;

  @include respond-to(m) {
    width: 280px;
  }
}

.sectionButtonContainer {
  margin: 0;
}

.sectionButton {
  width: 100%;
  background: $primary_btn_gradient;
  border-radius: 8px;
}
