@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

div.container {
  align-items: center;
  padding-bottom: 25px;
  padding-top: 42px;
  text-align: center;

  @include respond-to(m) {
    padding-top: 94px;
  }

  @include respond-to(l) {
    padding-top: 60px;
    padding-bottom: 75px;
  }

  & img {
    width: 200px;
    height: 200px;

    @include respond-to(m) {
      width: 310px;
      height: 310px;
    }

    @include respond-to(l) {
      width: 262px;
      height: 262px;
    }
  }
}

.title {
  color: $primary_white;
  @include title;
  margin-bottom: 14px;

  @include respond-to(m) {
    margin-bottom: 30px;
  }

  @include respond-to(l) {
    margin-bottom: 20px;
  }
}

.text {
  font-size: 14px;
  color: $secondary_text;
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 0;

  @include respond-to(m) {
    max-width: 480px;
    font-size: 16px;
  }

  @include respond-to(l) {
    max-width: 524px;
  }
}

.img {
  width: 200px;
  height: 200px;

  @include respond-to(m) {
    width: 295px;
    height: 295px;
  }
}

.imageWrapper {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 10px;
  padding-bottom: 30px;

  @include respond-to(m) {
    padding-top: 20px;
    padding-bottom: 75px;
  }

  @include respond-to(l) {
    padding-bottom: 20px;
  }
}

.link {
  color: $primary_white;
  max-width: 300px;
  @include respond-to(m) {
    max-width: 340px;
  }
}

.policy {
  font-size: 12px;
  color: $secondary_text;
  line-height: 18px;
  margin-top: 18px;

  @include respond-to(m) {
    margin-top: 46px;
    font-size: 14px;
    line-height: 24px;
  }

  @include respond-to(l) {
    margin-top: 30px;
  }
}



.button {
  width: 100%;
}

.buttonWrapper {
  margin-top: 0;
  margin-bottom: 0;
}
