@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
@import "src/assets/scss/vars.module";

.container {
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 17px;
  flex-grow: 1;
  @include respond-to(m) {
    margin-top: 22px;
  }
  @include respond-to(l) {
    margin-top: 36px;
    flex-direction: column
  }
}

.block {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  @include respond-to(l) {
    flex-direction: row;
    height: auto;
    flex-grow: 1;
    padding-bottom: 80px;
    & > div {
      width: 50%

    }
  }
}

.mobile {
  @include respond-to(l) {
    display: none;
  }
}

.desktop {
  display: none;
  @include respond-to(l) {
    display: block;
  }
}

.wrapper {
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @include respond-to(l) {
    max-width: fit-content;
    width: initial;
  }
}

.content, .radioContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include respond-to(l) {
    max-width: 388px
  }
}

.content {
  justify-content: space-between;
  @include respond-to(l) {
    justify-content: center
  }
}

.radioContent {
  justify-content: flex-start;
  @include respond-to(l) {
    justify-content: center
  }
}

.title {
  @include respond-to(l) {
    border: 1px solid;
    border-image: $primary_border_backward_gradient 0 1 0 0;
    padding-right: 50px;
    padding-bottom: 80px;
  }

  & > div {
    @include respond-to(l) {
      @include flex(column, center, center);
      height: 100%;

      & .heading {
        font-size: 36px;
      }
    }
  }

  & h1 {
    @include title;
    margin-bottom: 20px;
    @include respond-to(m) {
      margin-bottom: 30px
    }
    @include respond-to(l) {
      margin-bottom: 50px;
    }
  }
}

.illustration {
  display: none;
  @include respond-to(l) {
    display: initial;
  }
}

.back {
  margin-bottom: 0;

  @include respond-to(m) {
    margin-bottom: 20px
  }

  @include respond-to(l) {
    margin-bottom: 0;
  }
}
