@import "src/assets/scss/breakpoints";

.circle {
  transform: rotate(-90deg) scaleY(-1);
  width: 174px;
  height: 174px;
  @include respond-to(m) {
    width: 270px;
    height: 270px
  }
  @include respond-to(l) {
    width: 174px;
    height: 174px;
  }
}
