@import "src/assets/scss/vars.module";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.zodiacs {
  width: 91%;
  margin: 30px auto 0 auto;
  @include respond-to(l) {
    max-width: 51%;
  }
  @include respond-to(m) {
    display: flex;
  }
}

.copyright {
  background: #1D3F68;
}
